html,
body {
  min-height: 100%;
}



.hidden {
  display: none;
}

.loading {
  z-index: 1000;
}

a.nav-link {
  font-size: 0.9em !important;
}


.input-group-custom {
  border-right: none !important;
  background-color: transparent !important;
}

.input-with-icon {
  border-left: none !important;
  background-color: transparent !important;
  outline: none !important;
}

input.input-with-icon:focus {
  outline: 0 none !important;
  background-color: transparent !important;
}

input:focus {
  outline: 0 none !important;
  background-color: transparent !important;
}

.input-group-2 {
  border: 1px gray solid !important;
  padding: 10px !important;
}


.bg {
  /* The image used */
  background-image: url("https://ufabet.com/Images/index/bg.jpg");

  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.gold {
  color: #DFCE95 !important;
}

.btn-gold {
  border-color: #DFCE95 !important;
  background-color: black !important;
  color: #DFCE95 !important;
}

hr.gold {
  background-color: #DFCE95 !important;
  color: #DFCE95 !important;
}

.h {
  color: #fff !important;
}


.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}


.my-modal-body {
  padding: 0px;
}


select>option {
  color: #000000 !important;
}