.button-flex {
  height: 100px;
  font-size: 14px;
}

.table-small * {
  font-size: 0.85em;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%
    /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}


.btn-game {
  background-color: #c36323 !important;
  border-color: #c36323 !important;
}


.btn-top-game {
  background-color: #8E40EE !important;
  border-color: #8E40EE !important;
  font-size: 0.95em !important;
}


.btn-top-cashback {
  background-color: #4000A3 !important;
  border-color: #4000A3 !important;
  font-size: 0.95em !important;
}

.btn-cashback {
  background-color: #FB6005 !important;
  border-color: #FB6005 !important;
}

.btn-promotion {
  background-color: #db34eb !important;
  border-color: #db34eb !important;
}


.btn-profile {
  background-color: #836503 !important;
  border-color: #836503 !important;
}