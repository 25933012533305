.login-form {
  background-color: transparent !important;
  padding: 10px !important;
  border-radius: 20px !important;
  box-shadow:0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19) !important;
  color: #DFCE95 !important;
}

.login-form svg {
  color: #DFCE95 !important;
}

.login-form input {
  
  color: #DFCE95 !important;
}

.login-form select {
  background-color: transparent !important;
  color: #DFCE95 !important;
}

.gold {
  color: #DFCE95 !important;
}